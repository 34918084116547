.cardTinder {
    position: relative;
    width: 600px;
    padding: 20px;
    max-width: 85vw;
    height: 50vh;
    border-radius: 20px;
    background-size: cover !important;
    background-position: center;
    box-shadow: 0px 18px 53px 0px rgba(0,0,0,0.3)
}

.cardTinder h3 {
    position: absolute;
    bottom: 10px;
    color: white;
}

.tinderCards__cardContainer {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

/* stacks cards on top of each other */
.swipe {
    position: absolute;
    background-color: white;
    border-radius: 20px;
}
