section {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 112px;
    border-radius: 6px;
    justify-content: space-between;
}

.product {
    display: flex;
    flex: 1;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

img,
svg {
    border-radius: 6px;
    margin: 10px;
    width: 54px;
    height: 57px;
}

h3,
h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    margin: 0;
}

h5 {
    opacity: 0.5;
}

button {
    height: 36px;
    background: #ea4c97;
    color: white;
    width: 100%;
    font-size: 14px;
    border: 0;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.6;
    border-radius: 0 0 6px 6px;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

button:hover {
    opacity: 0.8;
}

.payment-option {
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #ea4c9730;
    border-radius: 6px;
    height: 100px;
    width: auto;
    align-items: center;
    cursor: pointer;
    font: 18px Arial, sans-serif;
    color: #ea4c97;
    border: 2px solid #ea4c9700;
    box-shadow: none;
    transition: all 0.4s ease;
}

.payment-option.active {
    border: 2px solid #ea4c97;
    box-shadow: 0px 4px 5.5px 0px #ea4c971a;
}

/* .payment-image { */
.payment-option>img {
    height: 80px;
    width: 80px;
    border-radius: 4px;
    margin: 0;
    margin-right: 20px;
}