.header_guur {
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #f9f9f9;
    align-items: center;
}

.header__logo_guur {
    height: 40px;
    object-fit: contain;
}

.header__icon_guur {
    padding:20px;
    height: 65px !important;
    width: 65px !important;
    z-index: 333;
}
