
.match-container {
    display: flex;
    height: 100vh;
    background-color: #fedcdc;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.gradient-bg {
    background: linear-gradient(180deg, rgba(0,0,0,0), #e74995)
}

.interact-match {
    position: absolute;
    left: 50%;
    top: 80%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.bio {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.viewButton {
    right: 0px;
    max-width: 100px;
    bottom: 20px;
    background-color: #151515;
    color: white;
    font-weight: 500;
    padding: 10px;
    width: 100px;
}

.person-container {
    border-radius: 20px;
    background-color: #dd8fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 500px;
    height: 96vh;
    width: 100%;
    object-fit: cover;
    margin-top: 0px;
}
