.chatScreen__text {
  margin-left: 10px;
  background-color: lightgray;
  padding: 15px;
  border-radius: 20px;
  white-space: pre-line;
  color: #121212;
}

.chatScreen__message {
  padding: 20px;
  align-items: center;
  max-width: 50vw;
  text-align: left;
  display: flex;
  width: fit-content;
  padding-bottom: 0px !important;
}

.chatScreen__timestamp {
  text-align: center;
  padding: 20px;
  color: gray;
}

.ownMessage {
  margin-left: auto;
}

.chatScreen__owntext {
  margin-left: auto;
  background-color: #e74995;
  padding: 15px;
  border-radius: 20px;
  color: white;
  white-space: pre-line;
}

.chatScreen__form {
  display:flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 120px;
  height: auto;
  border-top: 1px solid lightgray;
  padding: 0;
  background-color: white;
}

.chatScreen__input {
  flex:7;
  padding: 10px;
  border: none;
  min-width: 60px;
  outline: none !important;

}

.chatScreen__button {
  margin-right: 5px;
  border: none;
  background-color: white;
  font-weight: bolder;
  color: #e74995;
  max-width: 165px;
  box-shadow: none;
  font-size: 16px;
  margin-top: 0.8%;
  border-left: 1px solid;
  flex: 1
}

.chatScreen {
  overflow: scroll;
}
