.borderNone {
    border: none !important;
    outline: none !important;
    outline-width: 0 !important;
}

.borderNone:focus {
    outline: none !important;
}

.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
    width: auto !important;
}

.css-1ae9t7h-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    width: auto !important;
}

.css-jro82b-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    width: auto !important;
}

.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton{
    width: auto !important;
}
