@font-face {
    font-family: 'Bonair';
    src: local('Bonair'), url(../../fonts/Bonair.ttf) format('truetype');

}


.accountTitle {
    font-size: 22px;
    text-align: center;
    color: gray;
    cursor: pointer;
    padding-right: 20px;
}

.accountActive {
    color: black !important;
}

.accountTitle:hover {
    color: black;
}


.center-content {
    display: table;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.login-container {
    flex: 1;
}

.login-input {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #787878;
    outline: none;
    max-width: 340px;
    padding-left: 48px;
    margin-left: 24px;
    letter-spacing: 28px;
    margin-top: 20px;
}

.connect-button {

    border-radius: 10px;
    border: 1px solid #ffffff;
    background-color: #37e7ad;
    font-size: 18px;
    font-weight: 500;
    color: white;
    width: 100%;
    height: 50px;
    margin-bottom: 50px;

}

.con-hov:hover {
    background-color: #2dc794 !important;
}

.con-hov {
    cursor: pointer;
}
