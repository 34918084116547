.profile_container {
    display: flex;
    height: 96vh;
    background-color: #fedcdc;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

.hobby_item {
    padding: 10px;
    background-color: #ffe5e5;
    border: 1px solid black;
    margin-right: 20px;
    text-align: center;
    border-radius: 15px;
    height: 40px;
    margin-top: 20px;

}

.profile_img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: auto;
    display: block;
    margin-top: 30px;
    object-fit: cover;
}

.profile_contents {
    border-radius: 20px;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 700px;
    height: auto;
    width: 100%;
    overflow: scroll;
    margin-top: 0px;
}
